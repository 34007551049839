import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Rate, Tag, Button } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentLines, faStar, faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import { faStars } from '@fortawesome/pro-light-svg-icons';

import Card from './Card';
import style from 'style';
import { useHistory, useLocation } from 'react-router-dom';
import * as track from 'lib/track';

dayjs.extend(relativeTime);
dayjs.extend(utc);


const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .header {
    border-bottom: 1px solid #f1f1f1;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-bottom {
    margin-left: 3px;
    font-size: 13px;
    margin-top: 5px;
    display: flex;
    column-gap: 5px;
    align-items: center;
  }
`;

const Reviews = ({ data, providerName, providerUuid, rating, count, isLoading }) => {

  const location = useLocation();
  const history = useHistory();
  const [showAll, setShowAll] = useState(false);

  const reviewData = showAll ? data : data.slice(0, 2);

  const clickWriteReview = () => {
    track.sendEvent('write provider review button', 'click', null, [providerUuid]);
    history.push(location.pathname + '#review');
  };

  return (
    <Card
      title="Reviews"
      id="reviews"
      icon={faStars}
      isLoading={isLoading}
      style={{paddingTop: '15px', paddingBottom: '35px'}}
      noMaxHeight
    >
      <Container>
        <div className="header">
          <div>
            <div>
              <Tag color={rating >= 4 ? 'green' : 'gold'}>{rating ? rating : 0}</Tag>
              <Rate style={{fontSize: '16px'}} disabled allowHalf value={rating} />
            </div>
            <div className="header-bottom">
              <FontAwesomeIcon icon={faCommentLines} />
              {count} Reviews
            </div>
          </div>
          <div>
            <Button type="primary" onClick={clickWriteReview}>
              <FontAwesomeIcon style={{marginRight: '5px'}} icon={faStar} />
              Write a review
            </Button>
          </div>
        </div>
        {reviewData.map((item, index) => <Review key={index} data={item} providerName={providerName} />)}
        {!!data.length && (
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button type="primary" onClick={() => setShowAll(prevState => !prevState)}>
              <FontAwesomeIcon style={{marginRight: '5px'}} icon={faLayerGroup}/>
              {showAll ? <>Minimise reviews</> : <>Show all reviews ({data.length})</>}
            </Button>
          </div>
        )}
      </Container>
    </Card>
  );
};

Reviews.propTypes = {
  data: PropTypes.array,
  providerName: PropTypes.string,
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  providerUuid: PropTypes.string,
};

const ReviewContainer = styled.div`
  background: #f6f6f6;
  border-radius: ${style.borderRadius.large};
  padding: 10px 15px;
`;

const Header = styled.div`
  .top {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-weight: 700;
  }
  .bottom {
    font-size: 13px;
  }
`;

const Content = styled.div`
  margin-top: 20px;
  white-space: pre-wrap;
`;

const Replies = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;


const Review = ({ data, providerName }) => {

  return (
    <ReviewContainer>
      <Header>
        <div className="top">
          <div>
            {data.authorUsername}
          </div>
          <Rate style={{fontSize: '16px'}} disabled allowHalf value={data.rating} />
        </div>
        <div className="bottom">
          {dayjs(data.publishedAt).utc(true).fromNow()}
        </div>
      </Header>
      <Content>
        {data.content}
      </Content>
      {!!data.comments && !!data.comments.length && (
        <Replies>
          {data.comments.map((item, index) => <Reply key={index} data={item} providerName={providerName} />)}
        </Replies>
      )}
    </ReviewContainer>
  );
};

Review.propTypes = {
  data: PropTypes.object,
  providerName: PropTypes.string,
};

const ReplyContainer = styled.div`
  border: 1px solid #e8e8e8;
  border-radius: ${style.borderRadius.large};
  padding: 10px 15px;
  margin-bottom: 10px;
`;

const ReplyHeader = styled.div`
  .top {
    font-weight: 600;
    margin-bottom: 3px;
  }
  .bottom {
    font-size: 13px;
    margin-left: 5px;
  }
`;

const ReplyContent = styled.div`
  margin-top: 20px;
  margin-left: 5px;
  white-space: pre-wrap;
`;


const Reply = ({ data, providerName }) => {
  return (
    <ReplyContainer>
      <ReplyHeader>
        <div className="top">
          <Tag style={{margin: 0}} color="cyan">Reply from {providerName}</Tag>
        </div>
        <div className="bottom">
          {dayjs(data.createdAt).utc(true).fromNow()}
        </div>
      </ReplyHeader>
      <ReplyContent>
        {data.content}
      </ReplyContent>
    </ReplyContainer>
  );
};

Reply.propTypes = {
  data: PropTypes.object,
  providerName: PropTypes.string,
};

export default Reviews;
