import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Alert, Button } from 'antd';
import { isValidPhoneNumber } from 'libphonenumber-js';
import Recaptcha from 'react-recaptcha';
import { makeStyles } from '@material-ui/core/styles';
import * as EmailValidator from 'email-validator';
import { CircularProgress } from '@material-ui/core';
import snakecaseKeys from 'snakecase-keys';

import { ReviewAPI, ServiceRequestAPI } from 'api';
import { Textbox } from 'providerSite/components/inputs';
import config from 'config';
import RecaptchaProvider from 'components/RecaptchaProvider';


const useStyles = makeStyles({
  header: {
    marginBottom: '1rem',
    fontSize: '20px',
  },
  text: {
    marginBottom: '1rem',
  },
  buttons: {
    padding: '1rem 0',
    display: 'flex',
    flexWrap: 'nowrap',
    columnGap: '10px',
  },
  button: {
    marginTop: '1rem',
    marginRight: '1rem',
  },
});

const PhoneNumberOTPForm = (
  {
    providerUuid,
    phoneNumber,
    setPhoneNumber,
    email,
    setEmail,
    displayName,
    setDisplayName,
    onSuccessOTPSend,
    onBackClick,
  }) => {

  const classes = useStyles();

  const [alreadyReviewed, setAlreadyReviewed] = useState(false);

  const reCaptchaRef = useRef();

  const [recaptchaValue, setRecaptchaValue] = useState();
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true);
  const [isOPTSubmitLoading, setIsOPTSubmitLoading] = useState(false);


  const handleCheckAlreadyReviewed = () => {
    ReviewAPI.alreadyReviewed(phoneNumber, 'provider', providerUuid)
      .then(r => setAlreadyReviewed(r?.payload?.reviewed))
      .catch(err => console.error(err));  // eslint-disable-line no-console
  };

  const handleOtpSubmit = () => {
    if (isOPTSubmitLoading) return;

    setIsOPTSubmitLoading(true);

    const data = {
      phoneNumber: phoneNumber,
      recaptchaCode: recaptchaValue,
    };

    ServiceRequestAPI.sendOTP(snakecaseKeys(data, {deep: true}))
      .then(() => {
        onSuccessOTPSend();
      })
      .catch(err => {
        console.error(err);   // eslint-disable-line no-console
      })
      .finally(() => setIsOPTSubmitLoading(false));
  };

  const handleEmailUpdate = value => {
    setEmail(value);
    if (!value) return;
    if (value.includes('@')) {
      const _val = value.split('@')[0];
      setDisplayName(_val);
      return;
    }
    setDisplayName(value);
  };


  return (
    <RecaptchaProvider>
      <div className={classes.header}>
        Almost there, we just need your <b>phone number</b> and <b>email</b> to validate this action!
      </div>
      <div>
        <Textbox
          title="Phone Number"
          defaultValue={phoneNumber}
          onChange={setPhoneNumber}
          validator={value => !!value && isValidPhoneNumber(value, 'AU')}
          errorMsg="Please enter a valid phone number"
          onBlur={() => handleCheckAlreadyReviewed()}
          colouredBorder
        />
        <Textbox
          title="Email Address"
          defaultValue={email}
          onChange={handleEmailUpdate}
          validator={val => !!val && EmailValidator.validate(val)}
          errorMsg="Please enter a valid email address"
          colouredBorder
        />
        <Textbox
          title="Display Name"
          defaultValue={displayName}
          onChange={setDisplayName}
          validator={val => !!val}
          errorMsg="Please enter a valid display name"
          colouredBorder
        />
      </div>
      <div>
        {alreadyReviewed &&
          <Alert
            showIcon
            type="warning"
            message={<>You have previously reviewed this service. If you submit this
              review it will replace your previous one.</>}
            style={{marginBottom: '10px'}}
          />
        }
      </div>
      <div className={classes.text}>
        Clickability users rely on the assurance that the reviews and experiences posted on this site are genuine.
      </div>
      <div className={classes.text}>
        <b><i>You will receive a one time code on your phone number shortly</i></b>
      </div>
      <Recaptcha
        ref={reCaptchaRef}
        sitekey={config.settings.RECAPTCHA_KEY}
        render="explicit"
        onloadCallback={() => {
          setRecaptchaValue(null);
          setIsRecaptchaLoading(false);
        }}
        verifyCallback={(code) => setRecaptchaValue(code)}
        expiredCallback={() => setRecaptchaValue(null)}
      />
      {isRecaptchaLoading && <CircularProgress size={30}/>}
      <div className={classes.buttons}>
        <Button type="primary" ghost onClick={onBackClick}>Back</Button>
        <Button
          type="primary"
          onClick={handleOtpSubmit}
          disabled={!email || !phoneNumber || !recaptchaValue || !displayName}
          loading={isOPTSubmitLoading}
        >
          Verify
        </Button>
      </div>
    </RecaptchaProvider>
  );

};

PhoneNumberOTPForm.propTypes = {
  providerUuid: PropTypes.string,
  phoneNumber: PropTypes.string,
  setPhoneNumber: PropTypes.func.isRequired,
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  displayName: PropTypes.string,
  setDisplayName: PropTypes.func.isRequired,
  onSuccessOTPSend: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
};

export default PhoneNumberOTPForm;
