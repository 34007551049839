import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Alert } from 'antd';

import { ReviewAPI } from 'api';
import { ReviewCompleted } from 'components/review/Completed';
import { ReviewStore } from 'store';
import WrongUser from 'components/review/WrongUser';
import OTP from 'components/OTP';
import ReviewForm  from 'components/forms/ReviewForm';
import * as track from '../../lib/track';
import PhoneNumberOTPForm from './PhoneNumberOTPForm';


const STEP_FORM = 'form';
const STEP_PHONE_NUMBER = 'phoneNumber';
const STEP_OTP = 'otp';
const STEP_COMPLETED = 'completed';
const STEP_WRONG_USER = 'wrongUser';


const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: '40rem',
    minHeight: '10rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  header: {
    marginBottom: '1rem',
    fontSize: '20px',
  },
  text: {
    marginBottom: '1rem',
  },
  alert: {
    marginBottom: '1rem',
  },
  buttons: {
    padding: '1rem 0',
    display: 'flex',
    flexWrap: 'nowrap',
    columnGap: '10px',
  },
  button: {
    marginTop: '1rem',
    marginRight: '1rem',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const WriteReview = ({ open, data, onClose, onEndorse }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isProvider = useSelector(state => state.auth.isProvider);
  const initialStep = isProvider ? STEP_WRONG_USER : STEP_FORM;
  const review = useSelector(state => state.review.activeReview);
  const [step, setStep] = useState(initialStep);
  const title = `Write a review for ${data.name}`;

  const [phoneNumber, setPhoneNumber] = useState();
  const [email, setEmail] = useState();
  const [displayName, setDisplayName] = useState();

  const handleClose = () => {
    !isProvider && setStep(STEP_FORM);
    onClose();
  };

  const onSubmit = async (newReview) => {
    if (newReview) {
      dispatch(ReviewStore.setActiveReview(newReview));
      setStep(STEP_PHONE_NUMBER);
    }
  };

  const handleReviewCreation = otpCode => {
    return ReviewAPI.createReview(data.uuid, review, phoneNumber, email, otpCode, displayName);
  };

  const renderForm = () => (
    <>
      <Alert
        showIcon
        type="info"
        message={<>For help on how to write a great review, check out
          our <Link className={classes.link} to="/review"><b>handy guide</b></Link>.</>}
      />
      <ReviewForm
        data={data}
        initialReview={review && data.uuid === review.item_uuid ? review : null}
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </>
  );

  const renderCompleted = () => <ReviewCompleted onClose={onClose} onEndorse={onEndorse} />;

  const renderPhoneNumberForm = () => (
    <PhoneNumberOTPForm
      providerUuid={data.uuid}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      email={email}
      setEmail={setEmail}
      displayName={displayName}
      setDisplayName={setDisplayName}
      onSuccessOTPSend={() => setStep(STEP_OTP)}
      onBackClick={() => setStep(STEP_FORM)}
    />
  );

  const renderOTP = () => (
    <OTP
      phoneNumber={phoneNumber}
      onComplete={handleReviewCreation}
      onSuccess={() => {
        track.sendEvent('review form', 'submit', { provider_uuid: data.uuid });
        setStep(STEP_COMPLETED);
      }}
      onCancel={() => setStep(STEP_PHONE_NUMBER)}
      onPhoneNumberClick={() => setStep(STEP_PHONE_NUMBER)}
    />
  );

  const renderWrongUser = () => <WrongUser onClose={onClose} action="review"/>;


  useEffect(() => {
    if (isProvider) setStep(STEP_WRONG_USER);
  }, []); // eslint-disable-line

  return (
    <Modal
      visible={open}
      title={title}
      onCancel={handleClose}
      footer={null}
      destroyOnClose
    >
      <div className={classes.content}>
        {step === STEP_FORM && renderForm()}
        {step === STEP_PHONE_NUMBER && renderPhoneNumberForm()}
        {step === STEP_OTP && renderOTP()}
        {step === STEP_COMPLETED && renderCompleted()}
        {step === STEP_WRONG_USER && renderWrongUser()}
      </div>
    </Modal>
  );

};

WriteReview.propTypes = {
  open: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onEndorse: PropTypes.func,
};

export default WriteReview;
